<template>
  <div v-if="modelImportModuleStatus === 'success'">
    <v-stepper
      v-model="stepper"
      flat
      height="100%"
      class="bg-transparent"
      non-linear
    >
      <v-stepper-header
        class="elevation-0 radius-0 ant-border-bottom px-5 background-white"
      >
        <v-stepper-step editable step="1"> Select Model </v-stepper-step>

        <v-divider />

        <v-stepper-step step="2">
          Import Module/Elements/Objects and SBS
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items style="height: calc(100% - 72px)">
        <v-stepper-content step="1" class="pa-0 full-height">
          <div class="d-flex flex-column full-height">
            <div class="flex-1">
              <div
                v-if="thumbnailsLoaded || modelImportModuleModels.length === 0"
                class="model-tiles-container pa-2"
              >
                <div
                  class="model-tile ant-glass-background d-flex flex-column"
                  @click="loadAutodeskConstructionCloud"
                >
                  <div
                    class="pa-2 d-flex flex-column flex-1 align-center justify-center"
                  >
                    Import model
                    <v-icon large class="model-tile-add-icon">
                      mdi-plus-box-outline
                    </v-icon>
                  </div>
                </div>
                <div
                  v-for="model in modelImportModuleModels"
                  :key="model.urn"
                  class="model-tile ant-glass-background d-flex flex-column pos-rel"
                  @click="selectModel(model)"
                >
                  <div class="pa-2">
                    {{ model.name }}
                  </div>
                  <v-img :src="model.thumbnail" alt="" height="100"> </v-img>
                  <v-chip
                    small
                    color="primary"
                    style="position: absolute; right: 10px; bottom: 7px"
                    >V{{ model.version }}</v-chip
                  >
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-center align-center full-height"
              >
                <ant-loading />
              </div>
            </div>
            <div
              v-if="isAccConnected"
              style="height: 50%"
              class="ant-border-top ant-glass-background radius-0 d-flex"
            >
              <div class="ant-border-right pa-2">
                <v-subheader>Projects</v-subheader>
                <div
                  v-for="project in modelImportModelAccHubProjects"
                  :key="project.id"
                  class="pa-1 my-1 acc-project-item"
                  :class="{ active: isProjectSelected(project) }"
                  @click="fetchAccProjectTopFolders(project)"
                >
                  {{ project.attributes.name }}
                </div>
              </div>
              <div class="pa-2 flex-1 overflow-y-auto d-flex flex-column">
                <v-subheader>Folders</v-subheader>
                <v-progress-linear
                  :style="{ opacity: accTreeLoading ? 1 : 0 }"
                  indeterminate
                  color="primary"
                  class="mb-2"
                  rounded
                />
                <div class="overflow-y-auto flex-1">
                  <v-treeview
                    :items="accTree"
                    :open.sync="folderTreeOpenItems"
                    :load-children="fetchFolderContents"
                    open-on-click
                    dense
                    item-key="id"
                    item-children="children"
                  >
                    <template #prepend="{ item, open }">
                      <v-icon v-if="item.type === 'folders'">
                        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                      </v-icon>
                      <v-icon v-else> mdi-cube </v-icon>
                    </template>
                    <template #append="{ item }">
                      <div v-if="item.type === 'items'" class="d-flex">
                        <v-spacer />
                        <v-select
                          v-if="item.displayVersions"
                          v-model="item.selectedVersion"
                          :items="item.versions"
                          item-value="id"
                          hide-details
                          dense
                          return-object
                          prepend-icon="mdi-note-multiple"
                          flat
                          placeholder="version"
                          item-text="attributes.versionNumber"
                        />
                        <v-tooltip left>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              :ripple="false"
                              v-bind="attrs"
                              :disabled="
                                item.displayVersions
                                  ? item.selectedVersion === undefined
                                  : false
                              "
                              @click="fetchVersionsOrAddToProject(item)"
                              v-on="on"
                            >
                              <v-icon> mdi-plus </v-icon>
                            </v-btn>
                          </template>
                          <span>{{
                            item.displayVersions
                              ? 'Add to project'
                              : 'fetch versions'
                          }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-treeview>
                </div>
              </div>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2" class="pa-0 full-height">
          <dhme-import-object-elements
            v-if="selectedModel && stepper === 2"
            :model="selectedModel"
            @importSuccess="stepper = 1"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
  <div v-else class="d-flex justify-center align-center full-height">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DHME_MODEL_IMPORT } from '@/modules/modules';
import AutodeskService from '@/services/forge/autodesk';
import AntLoading from '@/components/AntLoading.vue';
import {
  ACC_ROUTE,
  fetchFolderContents,
  fetchItemVersions,
  fetchTopFolders,
} from '@/services/forge/autodesk-construction-cloud';
import appConfig from '@/appConfig';
import DhmeImportObjectElements from '@/modules/daiwa-house-modular-europe/ModelImport/DhmeImportObjectElements.vue';

export default {
  name: 'DhmeModelImport',
  components: { DhmeImportObjectElements, AntLoading },
  data: () => {
    return {
      modelObjects: [],
      sbsTreeExample: [],
      stepper: 1,
      thumbnailsLoaded: false,
      selectedModel: undefined,
      selectedAccProject: undefined,
      folderTreeOpenItems: [],
      folderContents: [],
      accTree: [],
      accTreeLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'modelImportModuleData',
      'modelImportModuleStatus',
      'modelImportModuleModels',
      'modelImportAutodeskAccessToken',
      'modelImportModuleClient',
      'accAccessToken',
      'modelImportModelAccHubProjects',
      'modelImportModelAccProjectTopFolders',
    ]),
    isAccConnected() {
      return (
        this.accAccessToken !== undefined &&
        this.modelImportModuleStatus === 'success'
      );
    },
  },
  watch: {
    isAccConnected: {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetchAccHubProjects();
        }
      },
    },
  },
  mounted() {
    this.$store
      .dispatch('loadModelImportData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find(
          (m) => m.route === DHME_MODEL_IMPORT
        ).id,
        sessionId: this.$route.params.sessionId ?? null,
      })
      .then(() => {
        this.forgeAuthenticate().then(async () => {
          for (const model of this.modelImportModuleModels) {
            if (model.from_acc && !this.accAccessToken) {
              this.loadAutodeskConstructionCloud();
            }
            model.thumbnail = await this.fetchModelThumbnail(model);
          }
          this.thumbnailsLoaded = true;
        });
      });
  },
  methods: {
    isProjectSelected(project) {
      if (this.selectedAccProject) {
        return this.selectedAccProject.id === project.id;
      }
      return false;
    },
    fetchAccHubProjects() {
      this.$store.dispatch('fetchAccHubProjects');
    },
    async fetchAccProjectTopFolders(project) {
      this.accTreeLoading = true;
      this.accTree = [];
      this.selectedAccProject = project;
      const topFolders = await fetchTopFolders(
        this.accAccessToken,
        this.modelImportModuleClient.acc_hub,
        project.id
      );
      topFolders.forEach((folder) => {
        this.accTree.push({
          id: folder.id,
          type: folder.type,
          name: folder.attributes.name,
          children: [],
        });
      });
      this.accTreeLoading = false;
    },
    fetchFolderContents(item) {
      if (item.type === 'folders') {
        this.accTreeLoading = true;
        fetchFolderContents(
          this.accAccessToken,
          this.selectedAccProject.id,
          item.id
        ).then((contents) => {
          let treeContent = [];
          contents.forEach((contentItem) => {
            let data = {
              id: contentItem.id,
              name:
                contentItem.attributes.name !== undefined
                  ? contentItem.attributes.name
                  : contentItem.attributes.displayName,
              type: contentItem.type,
            };
            if (data.type === 'folders') {
              data.children = [];
            }
            if (data.type === 'items') {
              data.displayVersions = false;
            }
            treeContent.push(data);
          });
          item.children.push(...treeContent);
          this.folderTreeOpenItems.push(item.id);
          this.accTreeLoading = false;
          return contents;
        });
      }
    },
    async fetchVersionsOrAddToProject(item) {
      if (!item.displayVersions) {
        // fetch versions
        item.versions = await fetchItemVersions(
          this.accAccessToken,
          this.selectedAccProject.id,
          item.id
        );
        item.displayVersions = true;
      } else {
        // add to project
        const data = {
          project: {
            id: this.project.id,
          },
          table: {
            id: this.modelImportModuleData.CFFA_FORGE_MODELS.id,
          },
          record: {
            urn: item.selectedVersion.relationships.derivatives.data.id,
            name: item.selectedVersion.attributes.name,
            version: item.selectedVersion.attributes.versionNumber,
            from_acc: true,
          },
        };
        await this.$store.dispatch('addModelToProject', data);
      }
    },
    restartModelImport() {
      this.selectedModel = undefined;
      this.stepper = 1;
    },
    async forgeAuthenticate() {
      let credentials = this.modelImportModuleClient;
      await this.$store.dispatch('saveCredentials', credentials);
      const token = await AutodeskService.fetchToken(
        credentials.client_id,
        credentials.secret,
        credentials.server_region
      );
      this.$store.commit('set_autodesk_authentication_token', token);
    },
    async fetchModelThumbnail(model) {
      let credentials = this.modelImportModuleClient;
      let server = credentials.region === 'EMEA' ? 'regions/eu/' : '';

      // fetch thumbnail
      let url = `https://developer.api.autodesk.com/modelderivative/v2/${server}designdata/${model.urn}/thumbnail`;
      const headers = new Headers();
      headers.set(
        'Authorization',
        `Bearer ${
          model.from_acc
            ? this.accAccessToken
            : this.modelImportAutodeskAccessToken
        }`
      );
      const imageResponse = await fetch(url, { headers });
      // parse thumbnail to readable data
      const blob = await imageResponse.blob();
      return URL.createObjectURL(blob);
    },
    selectModel(model) {
      this.selectedModel = model;
      this.stepper = 2;
    },
    loadAutodeskConstructionCloud() {
      if (!this.accAccessToken) {
        this.$cookies.set(ACC_ROUTE, `${this.$route.path}`);
        window.open(
          `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=${
            appConfig.VUE_APP_ACC_CLIENT_ID
          }&redirect_uri=${encodeURI(
            appConfig.VUE_APP_ACC_CLIENT_CALLBACK_URL
          )}&scope=data:read`,
          '_self'
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.model-tiles-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: minmax(120px, auto);
  grid-gap: 1rem;
  overflow: auto;

  .model-tile {
    transition: 200ms;
    cursor: pointer;
    border-left: solid 4px var(--v-primary-base);

    &:hover {
      background-color: white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .model-tile-add-icon {
        color: var(--v-primary-base);
      }
    }
  }
}

.acc-project-item {
  transition: 200ms;
  cursor: pointer;

  &:hover {
    border-left: solid 4px var(--v-primary-base);
    background-color: white;
  }

  &.active {
    border-left: solid 4px var(--v-primary-base);
    background-color: white;
  }
}
</style>
<style>
.v-stepper__wrapper {
  height: 100%;
}
</style>
