<template>
  <div
    v-if="modelImportModelObjectsStatus === 'success'"
    class="d-flex flex-column full-height"
  >
    <div
      class="d-flex ant-glass-background ant-border-bottom radius-0 pa-2 mb-10"
    >
      {{ model.name }}
      <v-spacer />
      <div class="d-flex justify-center align-center mr-2">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" class="mr-2" v-on="on">
              mdi-cube
            </v-icon>
          </template>
          <span>Estimated modules found in model</span>
        </v-tooltip>
        {{ distinctModules.length }}
      </div>
      <div class="d-flex justify-center align-center mr-2">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" class="mr-2" v-on="on">
              mdi-cube-outline
            </v-icon>
          </template>
          <span>Estimated elements found in model</span>
        </v-tooltip>
        {{ distinctElements.length }}
      </div>
      <div class="d-flex justify-center align-center mr-2">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" class="mr-2" v-on="on">
              mdi-cube-unfolded
            </v-icon>
          </template>
          <span>Estimated objects found in model</span>
        </v-tooltip>
        {{ distinctObjects.length }}
      </div>
    </div>
    <div class="d-flex flex-1">
      <div class="d-flex flex-column flex-1 mx-2">
        <div
          class="d-flex align-center justify-center ant-glass-background pa-5 import-tile mb-5"
          :class="{
            'import-tile-imported': moduleMappingStatus,
          }"
        >
          <div class="d-flex flex-column">
            <v-icon class="import-tile-icon" large> mdi-cube</v-icon>
            <div>Import modules</div>
          </div>
        </div>
        <div class="ant-glass-background d-flex flex-column pa-2">
          <v-form ref="import-modules-form">
            <ant-input label="Module id">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.module_id"
                  hide-details
                  filled
                  dense
                  clearable
                  :rules="[rules.required]"
                  placeholder="Module id"
                  :disabled="moduleMappingStatus"
                  :items="mappingOptions"
                />
              </template>
            </ant-input>
            <ant-input label="Module type" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.module_type"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="Module type"
                  :items="mappingOptions"
                  :disabled="moduleMappingStatus"
                />
              </template>
            </ant-input>
            <div class="d-flex align-center mt-2">
              <v-btn
                class="flex-grow-1"
                small
                color="primary"
                elevation="0"
                :disabled="moduleMappingStatus"
                @click="importModules"
              >
                Next
                <v-icon>mdi-skip-next</v-icon>
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
      <div
        class="d-flex flex-column flex-1 mx-2"
        :class="{ 'inactive-import': !moduleMappingStatus }"
      >
        <div
          :class="{
            'import-tile-imported': elementMappingStatus,
          }"
          class="d-flex align-center justify-center ant-glass-background pa-5 import-tile mb-5"
        >
          <div class="d-flex flex-column">
            <v-icon class="import-tile-icon" large> mdi-cube-outline </v-icon>
            <div>Import elements</div>
          </div>
        </div>
        <div
          v-if="moduleMappingStatus"
          class="ant-glass-background d-flex flex-column pa-2"
        >
          <v-form ref="import-elements-form">
            <ant-input label="Composition id">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.composition_id"
                  hide-details
                  filled
                  dense
                  clearable
                  :rules="[rules.required]"
                  placeholder="Composition id"
                  :items="mappingOptions"
                  :disabled="elementMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Composition type">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.composition_type"
                  hide-details
                  filled
                  dense
                  clearable
                  :rules="[rules.required]"
                  placeholder="Composition type"
                  :items="mappingOptions"
                  :disabled="elementMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Element id">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_id"
                  hide-details
                  filled
                  dense
                  clearable
                  :rules="[rules.required]"
                  placeholder="Element id"
                  :items="mappingOptions"
                  :disabled="elementMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Element code">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_type"
                  hide-details
                  filled
                  dense
                  clearable
                  :rules="[rules.required]"
                  placeholder="Element code"
                  :items="mappingOptions"
                  :disabled="elementMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Element type">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_category"
                  hide-details
                  filled
                  dense
                  clearable
                  :rules="[rules.required]"
                  placeholder="Element type"
                  :items="mappingOptions"
                  :disabled="elementMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Element position">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_position"
                  hide-details
                  filled
                  dense
                  clearable
                  :rules="[rules.required]"
                  placeholder="Element position"
                  :items="mappingOptions"
                  :disabled="elementMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Element id lvl 2" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_id_level_2"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="Element id"
                  :items="mappingOptions"
                  :disabled="elementMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Element id lvl 3" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_id_level_3"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="Element id"
                  :items="mappingOptions"
                  :disabled="elementMappingStatus"
                />
              </template>
            </ant-input>
            <div class="d-flex align-center mt-2">
              <v-btn
                class="flex-grow-1"
                small
                color="primary"
                elevation="0"
                :disabled="elementMappingStatus"
                @click="importElements"
              >
                Next
                <v-icon>mdi-skip-next</v-icon>
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
      <div
        class="d-flex flex-column flex-1 mx-2"
        :class="{ 'inactive-import': !elementMappingStatus }"
      >
        <div
          :class="{
            'import-tile-imported': objectMappingStatus,
          }"
          class="d-flex align-center justify-center ant-glass-background pa-5 import-tile mb-5"
        >
          <div class="d-flex flex-column">
            <v-icon class="import-tile-icon" large> mdi-cube-unfolded </v-icon>
            <div>Import Objects</div>
          </div>
        </div>
        <div
          v-if="elementMappingStatus"
          class="ant-glass-background d-flex flex-column pa-2"
        >
          <v-form ref="import-objects-form">
            <ant-input label="Object id">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.object_id"
                  hide-details
                  filled
                  dense
                  clearable
                  :rules="[rules.required]"
                  placeholder="object id"
                  :items="mappingOptions"
                  :disabled="objectMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Object code" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.object_type"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="object code"
                  :items="mappingOptions"
                  :disabled="objectMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Name" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.name"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="name"
                  :items="mappingOptions"
                  :disabled="objectMappingStatus"
                />
              </template>
            </ant-input>

            <ant-input label="Height" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.height"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="height"
                  :items="mappingOptions"
                  :disabled="objectMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Length" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.length"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="length"
                  :items="mappingOptions"
                  :disabled="objectMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Width" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.width"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="width"
                  :items="mappingOptions"
                  :disabled="objectMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="Surface" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.surface"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="surface"
                  :items="mappingOptions"
                  :disabled="objectMappingStatus"
                />
              </template>
            </ant-input>
            <ant-input label="NLSFB" is-optional>
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.NLSFB"
                  hide-details
                  filled
                  dense
                  clearable
                  placeholder="nlsfb"
                  :items="mappingOptions"
                  :disabled="objectMappingStatus"
                />
              </template>
            </ant-input>
            <div class="d-flex align-center mt-2">
              <v-btn
                class="flex-grow-1"
                small
                color="primary"
                elevation="0"
                :disabled="objectMappingStatus"
                @click="importObjects"
              >
                Next
                <v-icon>mdi-skip-next</v-icon>
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </div>
    <div
      v-if="objectMappingStatus && elementMappingStatus && moduleMappingStatus"
      class="pa-5 d-flex justify-center"
    >
      <v-btn
        elevation="0"
        color="primary"
        :loading="isImporting"
        @click="sendImportRequest"
        >import modules, elements, objects and import SBS tree
      </v-btn>
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center full-height">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import { DHME_MODEL_IMPORT } from '@/modules/modules';
import AntInput from '@/components/AntInput.vue';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { getTask } from '@/services/api/task.api';
import LocalStorageService from '@/services/local-storage';

export default {
  name: 'DhmeImportObjectElements',
  components: { AntInput, AntLoading },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
      },
      modelMapping: {
        module_id: undefined,
        module_type: undefined,

        composition_id: undefined,
        composition_type: undefined,
        element_id: undefined,
        element_type: undefined,
        element_category: undefined,
        element_id_level_2: undefined,
        element_id_level_3: undefined,

        object_id: undefined,
        object_type: undefined,
        name: undefined,
        height: undefined,
        width: undefined,
        length: undefined,
        surface: undefined,
        NLSFB: undefined,
      },

      moduleMappingStatus: false,
      elementMappingStatus: false,
      objectMappingStatus: false,
      isImporting: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'modelImportModelObjects',
      'modelImportModelObjectsStatus',
      'modelImportModuleModelMappingTable',
      'modelImportModuleModulesTable',
      'modelImportModuleNonModulesTable',
      'modelImportModuleElementsTable',
      'modelImportModuleObjectsTable',
      'modelImportModuleModelMapping',
      'modelImportModuleClient',
      'modelImportAutodeskAccessToken',
      'modelImportModuleModelsTable',
      'accAccessToken',
    ]),
    distinctModules() {
      return [
        ...new Set(
          this.modelImportModelObjects
            .filter((object) => object.properties['DHME IDM']?.ModuleId)
            .filter(
              (object) =>
                !object.properties['DHME IDM']?.ModuleId.startsWith(
                  'NonModuleObject;'
                )
            )
            .map((record) => record.properties['DHME IDM']?.ModuleId)
        ),
      ];
    },
    distinctElements() {
      return [
        ...new Set(
          this.modelImportModelObjects
            .filter((object) => object.properties['DHME IDM']?.ElementId)
            .map((record) => record.properties['DHME IDM']?.ElementId)
        ),
      ];
    },
    distinctObjects() {
      return [
        ...new Set(
          this.modelImportModelObjects
            .filter((object) => object.properties['DHME IDM']?.ObjectId)
            .map((record) => record.properties['DHME IDM']?.ObjectId)
        ),
      ];
    },
    mappingOptions() {
      let optionsSet = new Set();
      this.modelImportModelObjects.forEach((obj) => {
        Object.keys(obj.properties)
          .filter((x) => x.startsWith('DHME IDM'))
          .forEach((y) => {
            Object.keys(obj.properties[y]).forEach((key) => {
              let option = `${y}.${key}`;
              if (!optionsSet.has(option)) {
                optionsSet.add(option);
              }
            });
          });
      });
      return Array.from(optionsSet).sort((a, b) => a.localeCompare(b));
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_MODEL_IMPORT
      ).id;
    },
  },
  mounted() {
    this.$store.dispatch('fetchModuleImportModelModelProperties', this.model);
    if (this.modelImportModuleModelMapping) {
      this.modelMapping = { ...this.modelImportModuleModelMapping };
    }
  },
  methods: {
    async importModules() {
      if (this.$refs['import-modules-form'].validate()) {
        await this.updateModelMapping();
        this.moduleMappingStatus = true;
      }
    },

    async importElements() {
      if (this.$refs['import-elements-form'].validate()) {
        await this.updateModelMapping();
        this.elementMappingStatus = true;
      }
    },

    async importObjects() {
      if (this.$refs['import-objects-form'].validate()) {
        await this.updateModelMapping();
        this.objectMappingStatus = true;
      }
    },
    async updateModelMapping() {
      let record = { ...this.modelMapping };
      record.model = this.model.id;
      if (this.modelImportModuleModelMappingTable.records.length === 1) {
        // update
        let recordId = this.modelImportModuleModelMappingTable.records[0].id;
        delete record.id;
        let data = {
          project: {
            id: this.project.id,
          },
          table: {
            id: this.modelImportModuleModelMappingTable.id,
          },
          record: record,
        };

        await this.$store.dispatch('modelImportUpdateMapping', {
          recordId,
          body: data,
        });
      } else {
        // create
        let data = {
          project: {
            id: this.project.id,
          },
          table: {
            id: this.modelImportModuleModelMappingTable.id,
          },
          record: record,
        };
        await this.$store.dispatch('modelImportCreateMapping', { body: data });
      }
    },

    async sendImportRequest() {
      this.isImporting = true;
      let task = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'sendImportRequest',
        {
          server_region: this.modelImportModuleClient.server_region,
          autodesk_token: this.model.from_acc
            ? this.accAccessToken
            : this.modelImportAutodeskAccessToken,
          ANTToken: LocalStorageService.getToken(),
          project: this.project.id,
          objects_table: this.modelImportModuleObjectsTable.id,
          elements_table: this.modelImportModuleElementsTable.id,
          modules_table: this.modelImportModuleModulesTable.id,
          non_modules_table: this.modelImportModuleNonModulesTable.id,
          models_table: this.modelImportModuleModelsTable.id,
          mapping_table: this.modelImportModuleModelMappingTable.id,
          model: this.model.id,
        }
      );

      await this.checkTaskStatus(task.id);
    },

    async checkTaskStatus(taskId) {
      let task = await getTask(taskId);

      if (task.status === 'open') {
        setTimeout(() => {
          this.checkTaskStatus(taskId);
        }, 5000);
      } else {
        this.$store.commit('showNotification', {
          color: 'success',
          content:
            'Successfully imported all modules, elements, objects and created the SBS tree',
        });
        this.isImporting = false;
        this.$emit('importSuccess');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.inactive-import {
  pointer-events: none;
  opacity: 0.7;
}

.import-tile {
  cursor: pointer;
  transition: 200ms;

  &.import-tile-imported {
    box-shadow: inset 0 0 0 2px var(--v-success-base) !important;

    .import-tile-icon {
      color: var(--v-success-base) !important;
    }
  }

  &.import-tile-processing {
    box-shadow: inset 0 0 0 2px var(--v-warning-base) !important;

    .import-tile-icon {
      color: var(--v-warning-base) !important;
    }
  }

  &:hover {
    background-color: white;
    box-shadow: inset 0 0 0 2px var(--v-primary-base);

    .import-tile-icon {
      color: var(--v-primary-base);
    }
  }
}
</style>
